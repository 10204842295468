import { AnimatePresence, motion } from "framer-motion";

const colorPicker = Object.freeze({
    yellow: "#F7D44F",
    green: "#B6DE83",
    orange: "#F0A32F"
});

interface PropTypes {
    size?: "sm" | "md" | "lg";
    margin?: string | number;
}

const DotLoader = ({ size = "md", margin = "auto" }: PropTypes) => {
    return (
        <AnimatePresence>
            <motion.div
                style={{
                    display: "flex",
                    gap: 8,
                    margin,
                    alignItems: "center",
                    transform:
                        size === "sm"
                            ? "scale(0.7)"
                            : size === "md"
                            ? "scale(0.8)"
                            : "scale(1)"
                }}
                data-testid="motionDivWrapper"
            >
                <motion.div
                    style={{
                        width: 10,
                        height: 10,
                        borderRadius: 50,
                        backgroundColor: colorPicker.yellow,
                        scale: 1.5
                    }}
                    initial={{
                        backgroundColor: colorPicker.yellow
                    }}
                    animate={{
                        x: [0, 0, 18, 18, 36, 36],
                        scale: [1.5, 1, 1.5, 1, 1.5]
                    }}
                    transition={{
                        repeat: Infinity,
                        repeatType: "reverse",
                        duration: 1,
                        ease: "easeInOut"
                    }}
                    data-testid="motionDivOne"
                />
                <motion.div
                    style={{
                        width: 10,
                        height: 10,
                        borderRadius: 50,
                        backgroundColor: colorPicker.green
                    }}
                    initial={{
                        backgroundColor: colorPicker.green
                    }}
                    animate={{
                        x: [0, 0, -18, -18, -18, -18]
                    }}
                    transition={{
                        repeat: Infinity,
                        repeatType: "reverse",
                        duration: 1,
                        ease: "easeInOut"
                    }}
                    data-testid="motionDivTwo"
                />
                <motion.div
                    style={{
                        width: 10,
                        height: 10,
                        borderRadius: 50,
                        backgroundColor: colorPicker.orange
                    }}
                    initial={{
                        backgroundColor: colorPicker.orange
                    }}
                    animate={{
                        x: [0, 0, 0, 0, -18, -18]
                    }}
                    transition={{
                        repeat: Infinity,
                        repeatType: "reverse",
                        duration: 1,
                        ease: "easeInOut"
                    }}
                    data-testid="motionDivThree"
                />
            </motion.div>
        </AnimatePresence>
    );
};

export default DotLoader;
